import gql from 'graphql-tag'

export const ACTOR_QUERY = gql`
  query Actor {
    me {
      id
      email
      firstName
      lastName
      companyName
      roles
      jobTitle
      avatar
      subscriptionStatus
      phoneNumber
      country
      isPowerHost
      contracts(filter: { active: true }) {
        type
      }
      venues {
        id
        name
        leadFeedEnabled
        hasPublishedSpaces
        deletedAt
        spaces(includeUnpublished: true) {
          id
          status
          spaceName
        }
      }
    }
  }
`
