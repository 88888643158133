import { Typography } from 'antd'
import React, { CSSProperties } from 'react'

const { Title } = Typography
const titleStyle = {
  fontWeight: 400,
}

const SubTitle = ({
  title,
  style,
}: {
  title: string
  style?: CSSProperties
}) => (
  <Title level={4} style={{ ...titleStyle, ...style }}>
    {title}
  </Title>
)

export default SubTitle
