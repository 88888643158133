import { colours } from '@headbox-ui/themes'
import React from 'react'

const { grey, white } = colours

export const HOVER_BUTTON_ABSOLUTE: React.CSSProperties = {
  position: 'absolute',
  top: 11,
  left: -67,
  width: 175,
}

export const NOT_INTERESTED_BUTTON_STYLE: React.CSSProperties = {
  margin: '0px auto',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 5,
}

export const BUTTON_STYLE = {
  width: '100%',
}
export const RIGHT_BUTTON = {
  paddingLeft: 7,
  paddingRight: 7,
}

const tagHeight = '24pt'
export const TAG_STYLE = {
  fontSize: '12pt',
  height: tagHeight,
  lineHeight: tagHeight,
  backgroundColor: 'white',
  marginBottom: '8px',
}

export const CONTENT_STYLE = {
  backgroundColor: (grey as Record<string, string>)[98],
  padding: '20px 32px',
}

export const CARD_SHADOW_STYLE = {
  boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
}

// currently only used in venues board
export const CARD_STYLE = {
  ...CARD_SHADOW_STYLE,
  margin: '0px 5px 10px',
  width: '100%',
  height: 'fit-content',
  maxWidth: 520,
}

export const LAYOUT_STYLE = {
  margin: '0 auto',
  maxWidth: 2200,
  minWidth: 880,
  minHeight: '100vh',
}

export const SIDER_STYLE = {
  borderRight: `${(grey as Record<string, string>)[99]} 1px solid`,
  padding: '25px 0px',
}

export const TABLE_STYLE: React.CSSProperties = {
  WebkitOverflowScrolling: 'touch',
  backgroundColor: white,
  maxWidth: '100%',
  overflowX: 'auto',
  cursor: 'pointer',
}

export const TABLE_FOOTER_STYLE: React.CSSProperties = {
  position: 'relative',
  display: 'inline-flex',
  backgroundColor: white,
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  padding: '56px 16px 16px',
  marginBottom: '64px',
}
export const TITLE_ROW_STYLE: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 30,
  margin: '0 0 32px',
}

export const ICON_STYLE = {
  marginRight: 8,
}

export const TEXT_WITH_ICON_STYLE = {
  verticalAlign: 'text-bottom',
}

export const STAT_STYLE = { fontSize: 22 }

export const CONTROLS_STYLE = {
  paddingTop: 8,
  paddingBottom: 16,
}

export const LOADING_NEW_DATA_STYLE = {
  opacity: 0.3,
  transition: 'opacity 0.4s',
}

export const GUEST_STATUS_CONTAINER = {
  minWidth: '170px',
}

export const INTERESTED_GUEST_STYLE = {
  width: '100%',
}

export const INTERESTED_GUEST_OPTION_STYLE = {
  margin: '2px 4px',
  padding: '5px 10px',
  borderRadius: '2px',
}

export const ERROR_CONTAINER = {
  transition: 'all 0.25s',
  width: 200,
  height: 30,
  textAlign: 'center',
  border: '1px solid #BFBFBF',
  borderRadius: '4px',
  backgroundColor: '#BFBFBF',
}

export const ERROR_TEXT = {
  transition: 'all 0.25s',
  marginBottom: 0,
  color: 'black',
  lineHeight: '30px',
}

export const INVISIBLE_POPOVER = {
  background: 'none',
  border: 'none',
}

export const TABLE_HEADER_OVERLAY_STYLE: React.CSSProperties = {
  position: 'relative',
  zIndex: 10,
  bottom: -15,
  marginLeft: 15,
  height: 0,
}

export const TABLE_FOOTER_SPIN_STYLE: React.CSSProperties = {
  textAlign: 'center',
  position: 'absolute',
  height: '0px',
  width: '100%',
  pointerEvents: 'none',
  top: '10px',
}
