import React, { useState } from 'react'
import { generatePendoId } from '../../Utilities'
import { Drawer, Button, Typography, Layout } from 'antd'
import { DEFAULT_MD_QUERY, SM_SCREEN_DRAWER } from '../../Constants'
import { SIDER_STYLE } from '../../Styles'
import Media from 'react-media'

const { Title } = Typography
const { Sider } = Layout

const Aside = ({ children, siderTitle }) => {
  const [open, setOpen] = useState(false)

  return (
    <Media query={DEFAULT_MD_QUERY}>
      {matches => {
        return matches ? (
          <Sider
            breakpoint="lg"
            width={250}
            collapsedWidth={0}
            trigger={null}
            style={SIDER_STYLE}
            data-pid={generatePendoId('sider', siderTitle)}
          >
            <Title level={4} style={{ fontWeight: 400 }} type="secondary">
              {siderTitle}
            </Title>
            {children}
          </Sider>
        ) : (
          <>
            {!open && (
              <Button
                onClick={() => setOpen(!open)}
                style={{
                  width: 'fit-content',
                  position: 'absolute',
                  top: '226px',
                  right: '18px',
                  zIndex: 2,
                }}
                data-pid={generatePendoId('button-for-drawer', siderTitle)}
              >
                {siderTitle}
              </Button>
            )}
            <Drawer
              visible={open}
              width={SM_SCREEN_DRAWER}
              placement="left"
              onClose={() => setOpen(false)}
              title={siderTitle}
              data-pid={generatePendoId('drawer', siderTitle)}
            >
              {children}
            </Drawer>
          </>
        )
      }}
    </Media>
  )
}

export default Aside
