import { Alert } from 'antd'
import React from 'react'
import { GENERIC_ERROR_MESSAGE } from '../../Constants'

export interface ErrorAlertProps {
  message?: React.ReactNode
  showIcon?: boolean
}

const ErrorAlert = ({ message, showIcon }: ErrorAlertProps) => (
  <Alert
    message={message ? message : GENERIC_ERROR_MESSAGE}
    type="error"
    showIcon={showIcon === undefined ? true : showIcon}
  />
)

export default ErrorAlert
