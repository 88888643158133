import { Actor } from '../../../Utilities/actor'
import mapActorToProductFruitsUser from './mapper/mapActorToProductFruitsUser'

// Product Fruits script loaded by Google Tag Manager when user-set event fired
const setProductFruitsUserFromActor = (user: Actor): void => {
  if (window.dataLayer === undefined) return

  window.dataLayer.push({
    productFruitsUser: mapActorToProductFruitsUser(user),
  })
  window.dataLayer.push({ event: 'product-fruits-user-set' })
}

export default setProductFruitsUserFromActor
