/* global location, localStorage */

import { SESSION_STORAGE } from '../Constants'
import jwt from 'jsonwebtoken'

export const setSession = (sessionName, setWithData) => {
  localStorage.setItem(
    sessionName,
    JSON.stringify({
      data: setWithData,
    })
  )
}

export const setSessionStorage = (roles, token) => {
  const payload = jwt.decode(token)
  localStorage.setItem(
    SESSION_STORAGE,
    JSON.stringify({
      roles: roles,
      token,
      expires: payload.exp,
    })
  )
}

export { useActor } from './actor'
export { generatePendoId } from './pendo'
export { useAuth } from './auth'
export { useHubSpot } from './hubspot'
// export { default as useAuth } from './auth'
