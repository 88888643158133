import { icons } from '@headbox-ui/themes'
import { Icon } from 'antd'
import React from 'react'
import { ICON_STYLE } from '../../Styles'

const {
  BoardroomLayout,
  CabaretLayout,
  ClassroomLayout,
  NotSureLayout,
  SeatingLayout,
  StandingLayout,
  TheatreLayout,
  UShapedLayout
} = icons

const layoutIcon = name => {
  switch (name) {
    case 'u-shaped':
      return UShapedLayout
    case 'standing':
      return StandingLayout
    case 'theatre':
      return TheatreLayout
    case 'classroom':
      return ClassroomLayout
    case 'boardroom':
      return BoardroomLayout
    case 'cabaret':
      return CabaretLayout
    case 'seating':
      return SeatingLayout
    default:
      return NotSureLayout
  }
}

const LayoutIcons = ({ layout }) => <Icon component={layoutIcon(layout)} style={ICON_STYLE} />

export default LayoutIcons
