import { ContractType, SpaceStatus } from '../../../../GraphQL/components'
import { Actor } from '../../../../Utilities/actor'
import { ProductFruitsUser } from '../productFruits'

const mapActorToProductFruitsUser = (actor: Actor): ProductFruitsUser => ({
  username: actor.id,
  email: actor.email,
  firstname: actor.firstName || '',
  lastname: actor.lastName || '',
  role: actor.roles != null ? actor.roles.join(', ') : undefined,
  props: {
    country: actor.country || '',
    lFSubscription:
      actor.contracts != null && actor.contracts.length > 0
        ? actor.contracts[0].type
        : ContractType.NoContract,
    powerHost: actor.isPowerHost,
    venues: actor.venues.some(({ spaces }) => spaces.length > 0),
    spaces: actor.venues.some(({ spaces }) =>
      spaces.some(({ status }) => status === SpaceStatus.Published)
    ),
  },
})

export default mapActorToProductFruitsUser
