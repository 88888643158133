import { useQuery } from '@apollo/react-hooks'
import { useEffect, useState } from 'react'
import { ACTOR_QUERY } from '../GraphQL/Queries/actor'
import { useAuth } from './auth'
import { User } from '../../../server/src/primaryAdapters/graphQL/types'

export type Actor = Omit<
  User,
  | 'avatar'
  | 'mobileNumber'
  | 'companyLogo'
  | 'powerHostExpiresAt'
  | 'subscribed'
  | 'filterSets'
  | 'widgetBacklinkMandatory'
  | 'regions'
>

export const useActor = (): [Actor | null, () => void] => {
  useAuth()
  const [actor, setActor] = useState<Actor | null>(null)

  const { data, refetch } = useQuery<{ me: Actor }>(ACTOR_QUERY, {
    returnPartialData: true,
    pollInterval: actor ? 0 : 500, // Required to work arround a bug where useQuery won't always trigger an update when `data` changes
  })

  useEffect(() => {
    if (data) {
      setActor(data.me)
    }
  }, [data])

  return [actor, refetch]
}
