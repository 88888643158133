import { Button, Card, Result } from 'antd'
import React from 'react'
import { CONTENT_STYLE } from '../../Styles'

const errorMessageStyle: React.CSSProperties = {
  ...CONTENT_STYLE,
  height: '100%',
  width: '100%',
  position: 'absolute',
}

export interface ErrorMessageProps {
  title: string
  buttonText: string
  buttonLink: string
}

const ErrorMesssage = ({
  title,
  buttonText,
  buttonLink,
}: ErrorMessageProps) => {
  return (
    <div style={errorMessageStyle}>
      <Card bordered={false} style={{ backgroundColor: 'transparent' }}>
        <Result
          title={title}
          extra={
            <Button
              type="primary"
              onClick={() => window.location.replace(buttonLink)}
            >
              {buttonText}
            </Button>
          }
        />
      </Card>
    </div>
  )
}

export default ErrorMesssage
