import { useEffect } from 'react'
import { useActor } from '../../Utilities/actor'
import setProductFruitsUserFromActor from './utilities/setProductFruitsUserFromActor'

const useProductFruits = (): void => {
  const [actor] = useActor()

  useEffect(() => {
    if (!actor) return

    setProductFruitsUserFromActor(actor)
  }, [actor])
}

export default useProductFruits
