// AUTO-GENERATED. DO NOT TOUCH
// generated with: `npm run codegen:client`
// tslint:disable

export type Maybe<T> = T | null

export interface ContractsFilter {
  /** When 'active' is set to true, only confirmed, in-date contracts will be returned */
  active?: Maybe<boolean>
}

export interface EnquiryFiltersInput {
  minCapacity?: Maybe<number>

  maxCapacity?: Maybe<number>

  minGuestsCount?: Maybe<number>

  maxGuestsCount?: Maybe<number>

  minBudget?: Maybe<number>

  maxBudget?: Maybe<number>

  postalCodes?: Maybe<PostalCode[]>

  eventTypes?: Maybe<string[]>

  fromEventDate?: Maybe<string>

  maxAgeInDays?: Maybe<number>

  statuses?: Maybe<EnquiryStatus[]>

  excludeHostIds?: Maybe<number[]>

  collectionIds?: Maybe<number[]>

  guestId?: Maybe<number>

  guestIds?: Maybe<number[]>

  regions?: Maybe<string[]>

  filterSetId?: Maybe<number>

  hostId?: Maybe<number>

  excludeNotInterested?: Maybe<boolean>

  excludeMessaged?: Maybe<boolean>

  excludeClosed?: Maybe<boolean>

  excludePoorFits?: Maybe<boolean>

  showOnly?: Maybe<EnquiryShowOption[]>

  maxNotificationBatchesSent?: Maybe<number>

  excludePriorityBlocked?: Maybe<boolean>

  hasUnsentNotifications?: Maybe<boolean>
}

export interface PageInput {
  number: number

  size: number
}

export interface EnquirySortInput {
  field: EnquirySortField

  direction: SortDirection
}

export interface EnquirySearchFields {
  /** Uses case-insensitive substring matching */
  guestEmail?: Maybe<string>
}

export interface ContractFiltersInput {
  userId?: Maybe<number>

  userIds?: Maybe<(Maybe<number>)[]>

  active?: Maybe<boolean>

  pending?: Maybe<boolean>

  expired?: Maybe<boolean>

  regions?: Maybe<string[]>
}

export interface ContractSortInput {
  field: ContractSortField

  direction: SortDirection
}

export interface MessageFiltersInput {
  receiverId?: Maybe<number>

  interestExpressed?: Maybe<boolean>

  hostId?: Maybe<number>

  enquiryUUID?: Maybe<string>

  declined?: Maybe<boolean>
}

export interface MessageSortInput {
  field: MessageSortField

  direction: SortDirection
}

export interface InterestFiltersInput {
  hostId?: Maybe<number>

  guestId?: Maybe<number>

  guestInitiated?: Maybe<boolean>

  matched?: Maybe<boolean>

  enquiryId?: Maybe<number>

  enquiryIds?: Maybe<number[]>

  enquiryUUID?: Maybe<string>

  enquiryAccessSecret?: Maybe<string>

  regions?: Maybe<string[]>
  /** If true only includes interests associated with messages that have been declined  If false only includes interests associated with messages that have not been declined If set will always exclude direct enquiries */
  messageDeclined?: Maybe<boolean>
}

export interface InterestSortInput {
  field: InterestSortField

  direction: SortDirection
}

export interface MessageInput {
  venueId: number

  text: string

  email: string

  phone?: Maybe<string>

  availabilityDetails?: Maybe<string>

  pricingDetails: string

  isAvailable: boolean
}

export interface EnquiryUpdateInput {
  contactDetails: EnquiryContactPreference[]
}
/** DEPRICATED: use FilterSetInput */
export interface NotificationPreferenceInput {
  enabled?: Maybe<boolean>

  minBudget?: Maybe<number>

  maxCapacity?: Maybe<number>

  eventTypeIds?: Maybe<number[]>
}

export interface FilterSetUpdateInput {
  name?: Maybe<string>

  email?: Maybe<string>
  /** The minimum budget for the filter set. If budgetPerHead is true, this is the minimum budget per head, otherwise it is the minimum budget for the enquiry. */
  minBudget?: Maybe<number>
  /** Whether the minBudget is per head or for the entire enquiry. */
  budgetPerHead?: Maybe<boolean>

  minCapacity?: Maybe<number>

  maxCapacity?: Maybe<number>

  eventTypeIds?: Maybe<number[]>
  /** Month of the year (1-12) */
  monthsOfYear?: Maybe<number[]>
  /** Days of week are represented as numbers from 0 to 6, where 0 is Sunday and 6 is Saturday. */
  daysOfWeek?: Maybe<number[]>

  excludedFacilities?: Maybe<number[]>

  venueIds?: Maybe<number[]>

  audience?: Maybe<Audience[]>

  sendNotificationsFor: SendNotificationsForOption
  /** The earliest the event can start, in minutes since 00:00 of the *first* day. E.g. for 07:30am is mapped to 450, but 01:05am the *next* day is 1505 */
  minTimeOfDay?: Maybe<number>
  /** The latest the event can end, in minutes since from 00:00 of the *first* day. E.g. for 07:30am is mapped to 450, but 01:05am the *next* day is 1505 */
  maxTimeOfDay?: Maybe<number>
}

export interface FilterSetCreationInput {
  name: string

  email?: Maybe<string>
  /** The minimum budget for the filter set. If budgetPerHead is true, this is the minimum budget per head, otherwise it is the minimum budget for the enquiry. */
  minBudget?: Maybe<number>
  /** Whether the minBudget is per head or for the entire enquiry. */
  budgetPerHead?: Maybe<boolean>

  minCapacity?: Maybe<number>

  maxCapacity?: Maybe<number>

  eventTypeIds?: Maybe<number[]>
  /** Month of the year (1-12) */
  monthsOfYear?: Maybe<number[]>
  /** Days of week are represented as numbers from 0 to 6, where 0 is Sunday and 6 is Saturday. */
  daysOfWeek?: Maybe<number[]>

  excludedFacilities?: Maybe<number[]>

  venueIds?: Maybe<number[]>

  audience?: Maybe<Audience[]>

  sendNotificationsFor: SendNotificationsForOption
  /** The earliest the event can start, in minutes since 00:00 of the *first* day. E.g. for 07:30am is mapped to 450, but 01:05am the *next* day is 1505 */
  minTimeOfDay?: Maybe<number>
  /** The latest the event can end, in minutes since from 00:00 of the *first* day. E.g. for 07:30am is mapped to 450, but 01:05am the *next* day is 1505 */
  maxTimeOfDay?: Maybe<number>
}

export interface GrantAccessInput {
  userId: number

  type: ContractType

  endDate: DateTime

  isPowerHost: boolean

  requestSource: ContractRequestSource
}

export interface UpdateContractInput {
  userId: number

  contractId: number

  type: ContractType

  endDate: DateTime

  isPowerHost: boolean

  requestSource: ContractRequestSource
}

export interface TemplateInput {
  uuid?: Maybe<string>

  name: string

  message: string

  email: string

  phoneNumber?: Maybe<string>
}

export interface GexpReminderEmailFilters {
  enquiryIds?: Maybe<number[]>
}

export interface ViewOptionsInput {
  sortBy?: Maybe<ViewOptionsSortByType>

  showOnlyGoodFit?: Maybe<boolean>

  showOnlyUnread?: Maybe<boolean>

  showOnlyStarred?: Maybe<boolean>

  hideNotInterested?: Maybe<boolean>

  hideResponsesClosed?: Maybe<boolean>

  hideMessaged?: Maybe<boolean>

  hidePoorFit?: Maybe<boolean>
}

export interface LeadFeedEnabledInput {
  id: number

  leadFeedEnabled: boolean
}

export interface UserDetailsInput {
  phoneNumber: string
}

export interface SortInput {
  field: string

  direction: SortDirection
}

export interface ReminderInput {
  enquiryId: number

  reminderType: ReminderType
}

export enum EnquiryStatus {
  Active = 'active',
  Closed = 'closed',
  Duplicated = 'duplicated',
  Unqualifiable = 'unqualifiable',
  Takenbyams = 'takenbyams',
  Old = 'old',
  Private = 'private',
}

export enum Audience {
  Personal = 'personal',
  Professional = 'professional',
}

export enum EnquirySource {
  HeadBox = 'HeadBox',
  BriefBuilder = 'BriefBuilder',
}

export enum RelevancyFit {
  Good = 'good',
  Average = 'average',
  Poor = 'poor',
}

export enum MessageStatus {
  Created = 'CREATED',
  Send = 'SEND',
  Reviewed = 'REVIEWED',
  HeldUntilNextBatch = 'HELD_UNTIL_NEXT_BATCH',
}

export enum SubscriptionStatus {
  Unsubscribed = 'unsubscribed',
  Requested = 'requested',
  Subscribed = 'subscribed',
}

export enum ContractType {
  NoContract = 'no_contract',
  Basic = 'basic',
  Professional = 'professional',
  Premium = 'premium',
}

export enum ContractStatus {
  PendingRequest = 'pending_request',
  Accepted = 'accepted',
  Rejected = 'rejected',
  Deleted = 'deleted',
}

export enum ContractRequestSource {
  Admin = 'admin',
  Host = 'host',
}

export enum SendNotificationsForOption {
  None = 'none',
  GoodOnly = 'good_only',
  All = 'all',
}

export enum SpaceStatus {
  Overview = 'overview',
  Complete = 'complete',
  UnderReview = 'under_review',
  Reviewed = 'reviewed',
  Published = 'published',
}

export enum DeclineReasonId {
  LocationUnsuitable = 'location_unsuitable',
  FacilitiesMissing = 'facilities_missing',
  PricingTooHigh = 'pricing_too_high',
  PhotosUnappealing = 'photos_unappealing',
  CapacityWrong = 'capacity_wrong',
  StyleWrong = 'style_wrong',
  FoundAnotherVenue = 'found_another_venue',
  EventNoLongerHappening = 'event_no_longer_happening',
  DislikeVenues = 'dislike_venues',
  DateTimeIssues = 'date_time_issues',
  Other = 'other',
}

export enum DeclineSource {
  MessagePage = 'message_page',
  QuickDecline = 'quick_decline',
  MassDecline = 'mass_decline',
}

export enum EnquiryClosedSource {
  Modal = 'modal',
  EnquiryPage = 'enquiry_page',
  Admin = 'admin',
}

export enum EnquiryContactPreference {
  Phone = 'PHONE',
  Email = 'EMAIL',
  Text = 'TEXT',
}

export enum PostalCode {
  Ec = 'EC',
  Wc = 'WC',
  E = 'E',
  Se = 'SE',
  Sw = 'SW',
  W = 'W',
  Nw = 'NW',
  N = 'N',
}

export enum EnquiryShowOption {
  Starred = 'STARRED',
  Unread = 'UNREAD',
  GoodFit = 'GOOD_FIT',
}

export enum EnquirySortField {
  Id = 'id',
  CreateDate = 'createDate',
  EventType = 'eventType',
  Budget = 'budget',
  EventDate = 'eventDate',
  GuestsCount = 'guestsCount',
  RelevancyFit = 'relevancyFit',
  HostVisibilityDate = 'hostVisibilityDate',
}

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum ContractSortField {
  StartDate = 'startDate',
  EndDate = 'endDate',
  RequestedAt = 'requestedAt',
  RejectedAt = 'rejectedAt',
}

export enum MessageSortField {
  Id = 'id',
  CreationDate = 'creationDate',
  EventType = 'eventType',
  Budget = 'budget',
  EventDateFrom = 'eventDateFrom',
  GuestsCount = 'guestsCount',
  InterestExpressedAt = 'interestExpressedAt',
}

export enum DeclineReasonType {
  MassDecline = 'mass_decline',
  SingleMessage = 'single_message',
}

export enum InterestSortField {
  Id = 'id',
  EventType = 'eventType',
  Budget = 'budget',
  EventDateFrom = 'eventDateFrom',
  MatchedAt = 'matchedAt',
  GuestsCount = 'guestsCount',
  CreatedAt = 'createdAt',
}

export enum ContactStatus {
  New = 'new',
  Contacted = 'contacted',
  Won = 'won',
  Lost = 'lost',
}

export enum ReminderType {
  Received = 'RECEIVED',
  Seen = 'SEEN',
}

export enum ReminderSubtype {
  FirstReceivedNotSeen = 'FirstReceivedNotSeen',
  SecondReceivedNotSeen = 'SecondReceivedNotSeen',
  ThirdReceivedNotSeen = 'ThirdReceivedNotSeen',
  FirstSeenNotActioned = 'FirstSeenNotActioned',
  SecondSeenNotActioned = 'SecondSeenNotActioned',
  ThirdSeenNotActioned = 'ThirdSeenNotActioned',
}

export enum ViewOptionsSortByType {
  MostRecent = 'MOST_RECENT',
  HighestBudget = 'HIGHEST_BUDGET',
  EarliestEventDate = 'EARLIEST_EVENT_DATE',
  BestFit = 'BEST_FIT',
}

export type DateTime = any
