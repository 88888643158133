import { useEffect, useState } from 'react'
import { Actor } from './actor'

interface HubSpotParams {
  actor: Actor | null
  venuesCount?: number | null
}

export const useHubSpot = ({ actor, venuesCount }: HubSpotParams): void => {
  const [contacted, setContacted] = useState(false)

  useEffect(() => {
    if (contacted || (!actor || venuesCount == null)) return
    const hsq = window._hsq || []

    hsq.push([
      'identify',
      {
        email: actor.email,
        id: actor.id,
        subscriptionStatus: actor.subscriptionStatus,
        venuesCount,
      },
    ])
    setContacted(true)
  }, [actor, venuesCount])
}
