import React, { useState } from 'react'
import { CONTENT_STYLE } from '../../Styles'
import { Layout, Tabs, Icon } from 'antd'
import { ROUTES } from '../../Constants'
import queryString from 'query-string'

const { Content } = Layout
const { TabPane } = Tabs
const { LEADS, GUEST_INTEREST, INSIGHTS, PREFERENCES, TEMPLATES } = ROUTES

const tabStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
}

const SIDE_TABS = [
  {
    title: 'Lead Feed',
    icon: 'unordered-list',
    path: LEADS,
  },
  {
    title: 'Interested Guests',
    icon: 'heart',
    filled: true,
    path: GUEST_INTEREST,
  },
  {
    title: 'Insights',
    icon: 'area-chart',
    filled: false,
    path: INSIGHTS,
  },
  {
    title: 'Templates',

    icon: 'file-text',

    filled: true,

    path: TEMPLATES,

  },
  {
    title: 'Preferences',
    icon: 'setting',
    filled: true,
    path: PREFERENCES,
  },
]

const Foreground = ({ children }) => {
  const getActive = () => {
    const location = window.location
    const path = location.pathname
    const fromPageParsed = queryString.parse(location.search).from
    const sideTabPaths = SIDE_TABS.map(tab=>tab.path)

    if (fromPageParsed) {
      const fromPagePath = '/' + fromPageParsed
      return sideTabPaths.includes(fromPagePath)
      ? fromPagePath
      : LEADS 
    }

    return sideTabPaths.includes(path)
      ? path
      : LEADS
  }
  const [active, setActive] = useState(getActive())

  return (
    <Layout>
      <Content
        style={{
          ...CONTENT_STYLE,
          minHeight: '100vh',
        }}
      >
        <div style={{ margin: '0px auto', maxWidth: 1280 }}>
          {children}
        </div>
      </Content>
    </Layout>
  )
}

export default Foreground
