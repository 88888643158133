import { Spin } from 'antd'
import React, { useState } from 'react'
import Script from 'react-load-script'

interface Props {
  children: React.ReactNode
  googleMapsApiKey: string
}

const GoogleScript: React.FC<Props> = (props: Props): JSX.Element => {
  const [loaded, setLoaded] = useState(false)
  const language = document.documentElement.lang

  return (
    <>
      <Script
        attributes={{ id: 'googleMaps' }}
        onLoad={() => setLoaded(true)}
        url={`https://maps.googleapis.com/maps/api/js?key=${
          props.googleMapsApiKey
        }&libraries=geometry,drawing,places&language=${language}`}
      />

      {loaded ? props.children : <Spin />}
    </>
  )
}

export default GoogleScript
