import _ from 'lodash'

const {
  token,
  services,
  jwtCookieName,
  refreshTokenCookieName,
  messageGexpPreviewURI,
} = (window as any).env

export { MAX_ENQUIRY_RESPONSES } from '../../../server/src/app/entities/Enquiry'

export const AUTH_TOKEN = token || ''

export const HEADBOX_ROOT_SERVICE = services.root
export const AUTH_SERVICE = services.auth
export const GUEST_EVENTS_SERVICE = services.guestEvents
export const HEADBOX_ROOT_URI = services.root
export const HOST_LISTINGS_MY_VENUES_URI = services.hostListing
export const LEAD_FEED_BASE_URI = services.leadFeedBaseUri
export const LEAD_FEED_PROMO_URI = services.leadFeedPromoUri
export const REFRESH_TOKEN_COOKIE_NAME = refreshTokenCookieName
export const JWT_COOKIE_NAME = jwtCookieName
export const GOOGLE_MAPS_API_KEY = services.googleMapsApiKey
export const SENTRY_API_DSN = services.sentryApiDSN
export const SENTRY_ENVIRONMENT = services.sentryEnvironment
export const MESSAGE_GEXP_PREVIEW_URI = services.messageGexpPreviewURI

export const GRAPH_ENDPOINT = `/api/graphql`
export const INSIGHTS_ENDPOINT = services.insightsUri
export const REST_ENDPOINT = `api/v1`

export const REST_AUTH_TOKEN = `Basic YmV0YXVzZXI6VHdpblBlYWtzMTk5MA==`

export const SESSION_STORAGE = 'session'
export const LINK_TO_RESPONSES_SESSION = 'linkToResponses'

export const NEXT_PUBLIC_ASSETS = services.nextPublicAssets
export const NEXT_PUBLIC_HEADER_APP_VERSION =
  services.nextPublicHeaderAppVersion

// roles
export const HOST_ROLE = 'host'
export const GUEST_ROLE = 'guest'

export const ROUTES = {
  HOME: '/',
  ADMIN: '/admin',
  LOGIN: '/login',
  ENQUIRIES: '/enquiries',
  LEADS: '/leadfeed',
  GUEST_INTEREST: '/interestedguests',
  SENT_MESSAGES: '/sentmessages',
  RESPONSES: '/responses',
  FEEDBACK: 'mailto:product@headbox.com',
  PREFERENCES: '/preferences',
  INSIGHTS: '/insights',
  TEMPLATES: '/templates',
  CREATE_TEMPLATE: '/createTemplate',
  EDIT_TEMPLATE: '/editTemplate/:uuid',
  MESSAGING: '/messaging/:uuid',
  YOUR_FILTERS: '/filters',
  CREATE_FILTER: '/filters/new',
  EDIT_FILTER: '/filters/:filterId/edit',
  MY_VENUES: HOST_LISTINGS_MY_VENUES_URI,
}

export const GREEN_COLOUR = '#008578'
export const RED_COLOUR = '#af241c'
export const GREY_COLOUR = '#909399'

export const LEAD_STATUS = {
  active: 'Active',
  duplicated: 'Duplicate',
  unqualifiable: 'Unqualifiable',
  takenbyams: 'Taken by AMs',
  old: 'Too old',
  closed: 'Closed',
}

export const SUBSCRIPTION_STATUS = {
  SUBSCRIBED: 'subscribed',
  REQUESTED: 'requested',
  NONE: 'unsubscribed',
}

export const GENERIC_ERROR_MESSAGE =
  'Oops... something went wrong. Please try again.'
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const ICON_COLOUR = 'rgba(0,0,0,.25)'
export const BACKGROUND_COLOUR = '#fffff'

export const FILTER_BY = {
  LOCATION: 'Locations (Postcodes)',
  COLLECTIONS: 'Event Types',
  CAPACITY: 'Maximum Capacity',
  MIN_BUDGET: 'Minimum Budget',
}

export const COUNTRY_CURRENCIES: Record<string, string> = {
  GB: 'GBP',
  AU: 'AUD',
  US: 'USD',
  IE: 'IE',
}
export const CURRENCY_SYMBOLS: Record<string, string> = {
  EUR: '€',
  GBP: '£',
  AUD: '$',
  USD: '$',
  IE: '€',
}
export const COUNTRY_CURRENCY_SYMBOLS = _.mapValues(
  COUNTRY_CURRENCIES,
  cur => CURRENCY_SYMBOLS[cur]
)

export const COUNTRY_NAMES = {
  GB: 'the United Kingdom',
  AU: 'Australia',
  US: 'the United States',
}

export const EVENT_SUMMARY_HEADERS = [
  'Event Type',
  'Event Name',
  'Budget',
  'Seating Style',
  'Number of Guests',
  'Event Start Date',
  'Timings / End Date',
  'Post Code',
]

export const NO_RESULTS = 'No results found'
export const NONE = '-'
export const CUTOFF_POINT = 3

export const AGE = 'Enquiry age'
export const BUDGET = 'Budget(£)'
export const GUESTS = 'Number of Guests'
export const LOCATION = 'Event Information'
export const TIME = 'Event Timings'
export const INTEREST = 'Express Interest'

// page titles
export const PAGE_TITLES = {
  '/admin': 'Lead Feed Admin',
  '/enquiries': 'Lead Feed',
  '/responses': 'Your Responses',
  '/login': 'Please log in to continue.',
  '/notFound': 'Page Not Found 😬',
}

// corresponds to Ant's 'md' breakpoint
export const DEFAULT_MD_QUERY = '(min-width: 769px)'
export const SM_SCREEN_DRAWER = '80%'
export const LG_SCREEN_DRAWER = '450px'

export const LOGOUT_REDIRECT_PATH = '/users/sign_out'
export const LOGIN_REDIRECT_PATH = '/users/sign_in?redirect_to='

export const filterDateInformation = {
  days: [
    { name: 'Monday', value: 1 },
    { name: 'Tuesday', value: 2 },
    { name: 'Wednesday', value: 3 },
    { name: 'Thursday', value: 4 },
    { name: 'Friday', value: 5 },
    { name: 'Saturday', value: 6 },
    { name: 'Sunday', value: 0 },
  ],
  months: [
    { name: 'January', value: 1 },
    { name: 'February', value: 2 },
    { name: 'March', value: 3 },
    { name: 'April', value: 4 },
    { name: 'May', value: 5 },
    { name: 'June', value: 6 },
    { name: 'July', value: 7 },
    { name: 'August', value: 8 },
    { name: 'September', value: 9 },
    { name: 'October', value: 10 },
    { name: 'November', value: 11 },
    { name: 'December', value: 12 },
  ],
}
