import { ApolloClient } from 'apollo-client'
import { BatchHttpLink } from 'apollo-link-batch-http'
import { ApolloLink } from 'apollo-link'
import { RestLink } from 'apollo-link-rest'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { useAuth } from '../../Utilities'
import { GRAPH_ENDPOINT, REST_ENDPOINT, INSIGHTS_ENDPOINT } from '../../Constants'
import { useState, useEffect } from 'react'
import { RetryLink } from 'apollo-link-retry'
import * as Sentry from '@sentry/react'

export const useClient = () => {
  const [, , , authToken] = useAuth()
  const [client, setClient] = useState(undefined)

  useEffect(() => {
    const restLink = () => new RestLink({ uri: REST_ENDPOINT })
    const httpLink = () => new BatchHttpLink({
        uri: GRAPH_ENDPOINT,
        headers: {
          'x-HeadBox-AccessToken': authToken,
        },
        batchInterval: 500, // in milliseconds
    })

    const retryLink = () => new RetryLink({
      delay: {
        initial: 100,
        max: 5000,
        jitter: true,
      },
      attempts: {
        max: 10,
        retryIf: (error, op) => {
          if (error) {
            Sentry.captureMessage(`apollo ${op.operationName} failed: ${error.message}, retrying`)
            return true
          }
        }
      },
    })
    const insightsLink = new BatchHttpLink({
        uri: INSIGHTS_ENDPOINT,
        headers: {
          'x-HeadBox-AccessToken': authToken,
        },
        batchInterval: 500, // in milliseconds
    });
    const link = ApolloLink.split(
      operation => operation.getContext().clientName === "insights",
      ApolloLink.from([retryLink(), restLink(), insightsLink]),
      ApolloLink.from([retryLink(), restLink(), httpLink()]) // <= otherwise will send to this
    )
    const newClient = new ApolloClient({ link, cache: new InMemoryCache() })
    setClient(newClient)
  }, [authToken])

  return client
}
