import React, {useEffect} from 'react'
import { useAuth } from './Utilities'
import { Spin } from 'antd'
import Login from './Views/Login'
import { ApolloProvider } from 'react-apollo'
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks'
import { useClient } from './GraphQL/Client'
import { BrandWrap } from './Components/UI'
import NotAuthorizedMessage from './Components/NotAuthorizedMessage'
import Router from './Components/Router'
import { globalAuthContext } from '@headbox-ui/auth-hook'
import { GoogleScript } from './Components/GoogleScript'
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import enTranslations from './locales/en/translation.json'
import enUsTranslations from './locales/en-US/translation.json'


import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import {
  ApolloClient,
  InMemoryCache
} from '@apollo/client'
import {
  REFRESH_TOKEN_COOKIE_NAME,
  JWT_COOKIE_NAME,
  HEADBOX_ROOT_SERVICE,
  AUTH_SERVICE,
  GOOGLE_MAPS_API_KEY,
  SENTRY_API_DSN,
  SENTRY_ENVIRONMENT
} from './Constants'


const ALLOWED_ROLES = ['regional_manager', 'admin', 'host']

globalAuthContext.set({
  client: new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {}
    }),
    uri: AUTH_SERVICE,
  }),
  refreshTokenCookie: REFRESH_TOKEN_COOKIE_NAME,
  sessionTokenCookie: JWT_COOKIE_NAME,
  rootService: HEADBOX_ROOT_SERVICE,
})

Sentry.init({
  dsn: SENTRY_API_DSN,
  environment: SENTRY_ENVIRONMENT,
  integrations: [new Integrations.BrowserTracing()],
      release: window.SENTRY_RELEASE && window.SENTRY_RELEASE.id,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
i18next.init()

const App = () => {
  const [, , loading, authToken, { user }] = useAuth()
  const client = useClient()


  useEffect(() => {
    if(user){
      Sentry.configureScope(scope => {
        scope.setUser({
          id: user.id,
          email: user.email,
          name: user.firstName + ' ' + user.lastName,
        })
      })

      window.__pendoSDK.trackUserSession(user.id, (_) => Promise.resolve(user))
    }
  }, [user])

  useEffect(() => {
    if(i18next && user){
      const locale = user.country == 'US' ? 'en-US' : 'en-GB';
      i18next.changeLanguage(locale)
      
      i18next.addResources('en', 'leadfeed', enTranslations)
      i18next.addResources('en-US', 'leadfeed', enUsTranslations)
    }
  }, [user, i18next])

  if (loading || client === undefined) {
    return (
      <BrandWrap>
        <Spin />
      </BrandWrap>
    )
  }



  if (authToken) {
    const hasPermisison =
      user && ALLOWED_ROLES.some(role => user.roles.includes(role))
    if (!hasPermisison) {
      return <NotAuthorizedMessage />
    }
    return (
      <ApolloProvider client={client}>
        <ApolloHooksProvider client={client}>
          <I18nextProvider i18n={i18next}>
            <BrandWrap>
              <GoogleScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
                <Router />
              </GoogleScript>
            </BrandWrap>
          </I18nextProvider>
        </ApolloHooksProvider>
      </ApolloProvider>
    )
  }

  return (
    <BrandWrap>
      <Login />
    </BrandWrap>
  )
}

export default App
