import React from 'react'
import { Card, Layout, Button, Result } from 'antd'
import { CONTENT_STYLE } from '../../Styles'
import { generatePendoId, useAuth } from '../../Utilities'
import { MetaTitle } from '../../Components/UI'
import { Redirect } from 'react-router-dom'
import { ROUTES } from '../../Constants'
const { Content } = Layout

const Login = ({ location }) => {
  const [login, , , authToken] = useAuth()

  return (
    <>
      <MetaTitle title='Sign in' />
      {authToken && <Redirect to={ROUTES.LEADS} />}
      <Layout>
        <Content style={CONTENT_STYLE}>
          <Card style={{ margin: '0 auto', maxWidth: 480 }}>
            <Result
              status='info'
              title='Hold up!'
              subTitle='You need to sign in to continue.'
              extra={
                <Button
                  type='primary'
                  onClick={login}
                  data-pid={generatePendoId('button', 'Sign in')}>
                  Sign in
                </Button>
              }
            />
          </Card>
        </Content>
      </Layout>
    </>
  )
}

export default Login
