import React from 'react'
import { Helmet } from 'react-helmet'
import favicon from '../Images/favicon.png'

interface MetaTitleProps {
  title: string
}
const MetaTitle = ({ title }: MetaTitleProps) => (
  <Helmet>
    <title>{title} - HeadBox</title>
    <meta name={title} content={`${title} content`} />
  </Helmet>
)

export default MetaTitle
