import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from 'antd'
import { LAYOUT_STYLE } from '../../Styles'
import ErrorBoundary from './ErrorBoundary'
import Foreground from './Foreground'

const BrandWrap = ({ children }) => {
  return (
    <ErrorBoundary>
      <Layout style={LAYOUT_STYLE}>
        <Foreground>{children}</Foreground>
      </Layout>
    </ErrorBoundary>
  )
}

BrandWrap.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default BrandWrap
