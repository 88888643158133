import { Typography } from 'antd'
import React from 'react'
const { Title } = Typography

interface PageTitleProps {
  title: React.ReactNode
  subtitle?: string
  link?: JSX.Element
  style?: React.CSSProperties
  tag?: JSX.Element
}

const titleStyle = {
  fontWeight: 600,
  margin: '0 auto 8px',
  fontSize: '32px',
  color: '#262626',
}

const subTitleStyle = {
  fontWeight: 400,
  fontSize: '20px',
}
const PageTitle = ({ subtitle, title, link, tag, style }: PageTitleProps) => (
  <>
    <Title
      level={3}
      className="page-title"
      style={React.useMemo(() => ({ ...titleStyle, ...style }), [
        style,
        titleStyle,
      ])}
    >
      {title}
      {tag && tag}
    </Title>
    {subtitle && (
      <Title
        level={4}
        className="page-subtitle"
        type="secondary"
        style={{ ...titleStyle, ...subTitleStyle }}
      >
        {subtitle} {link}
      </Title>
    )}
  </>
)

export default PageTitle
