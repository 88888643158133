import { useGlobalAuth } from '@headbox-ui/auth-hook'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { CachePersistor } from 'apollo-cache-persist'
import queryString from 'query-string'
import { HEADBOX_ROOT_SERVICE, LOGIN_REDIRECT_PATH } from '../Constants'

const cache = new InMemoryCache()
const persistor = new CachePersistor({
  cache,
  storage: window.sessionStorage as any,
})
;(async () => {
  const { storage } = persistor
  const { key, storage: store } = storage

  if ((store as any)[key]) {
    const { ROOT_QUERY } = JSON.parse((store as any)[key])

    if (ROOT_QUERY.getSession) {
      await persistor.restore()
    } else {
      await persistor.purge()
    }
  }
  await persistor.restore()
})().catch(e => {
  // tslint:disable-next-line: no-console
  console.error(e)
})

export const useAuth = () => {
  const { logout, loading, jwt, user } = useGlobalAuth()

  const login = () => {
    const { pathname, origin, search } = window.location
    const { enquiryUUID } = queryString.parse(search)

    let url = HEADBOX_ROOT_SERVICE + LOGIN_REDIRECT_PATH + origin + pathname

    if (enquiryUUID) {
      url = `${url}?enquiryUUID=${enquiryUUID}`
    }

    window.location.assign(url)
  }

  return [login, logout, loading, jwt, { jwt, user }]
}
