import React, { Component } from 'react'
import { ErrorMessage } from '../../Components/UI'
import { ROUTES } from '../../Constants'
import * as Sentry from '@sentry/react'

class ErrorBoundary extends Component {
  state = { hasError: false }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // can log the error to an error reporting service
    Sentry.captureException(error)
  }

  render() {
    const { hasError } = this.state
    if (hasError) {
      return (
        <ErrorMessage title="Something went wrong..." buttonText="Go to Home Page" buttonLink={ROUTES.HOME} />
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
