import React from 'react'
import { Button, Result } from 'antd'
import { BrandWrap } from '../UI'
import { HEADBOX_ROOT_SERVICE } from '../../Constants'

const NotAuthorizedMessage = () => (
  <BrandWrap>
    <Result
      status="warning"
      title="Hold Up!"
      subTitle="You are not authorised to access this application."
      extra={
        <Button
          type="primary"
          onClick={() => window.location.assign(HEADBOX_ROOT_SERVICE)}
        >
          Return to HeadBox
        </Button>
      }
    />
  </BrandWrap>
)

export default NotAuthorizedMessage
