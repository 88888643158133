import React from 'react'
import { Typography, Icon } from 'antd'
const { Text } = Typography

const textStyle = {
  fontWeight: 400,
  marginLeft: 6,
  alignSelf: 'centre',
  lineHeight: 1.5,
  color: '#262626'
}

const layout = {
  display: 'grid',
  gridTemplateColumns: '30px auto',
  marginBottom: 16,
}

const RedTickMessage = props => {
  return (
    <div style={layout}>
      <Icon
        type="check-circle"
        theme="filled"
        style={{ alignSelf: 'center', fontSize: '24px', color: '#AF231C' }}
      />
      <Text style={textStyle}>{props.children}</Text>
    </div>
  )
}

export default RedTickMessage
