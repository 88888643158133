import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { ROUTES, GUEST_EVENTS_SERVICE } from '../../Constants'
import { Spin } from 'antd'
import useProductFruits from '../../Hooks/ProductFruits/useProductFruits'

const {
  ENQUIRIES,
  HOME,
  LOGIN,
  LEADS,
  GUEST_INTEREST,
  SENT_MESSAGES,
  PREFERENCES,
  RESPONSES,
  INSIGHTS,
  TEMPLATES,
  CREATE_TEMPLATE,
  EDIT_TEMPLATE,
  MESSAGING,
  YOUR_FILTERS,
  CREATE_FILTER,
  EDIT_FILTER
} = ROUTES

const LeadFeed = lazy(() =>
  import(
    /* webpackChunkName: "LeadFeed", webpackPrefetch: true  */
    '../../Views/LeadFeed/LeadFeed'
  )
)

const InterestedGuests = lazy(() =>
  import(
    /* webpackChunkName: "InterestedGuests", webpackPrefetch: true  */
    '../../Views/InterestedGuests'
  )
)

const SentMessages = lazy(() =>
  import(
    /* webpackChunkName: "SentMessages", webpackPrefetch: true  */
    '../../Views/SentMessages'
  )
)

const PageNotFound = lazy(() =>
  import(
    /* webpackChunkName: "PageNotFound", webpackPrefetch: true */
    '../../Views/PageNotFound'
  )
)
const Login = lazy(() =>
  import(
    /* webpackChunkName: "Login", webpackPrefetch: true */
    '../../Views/Login'
  )
)

const Insights = lazy(() =>
  import(
    /* webpackChunkName: "Insights", webpackPrefetch: true  */
    '../../Views/Insights/Insights'
  )
)

const Templates = lazy(() =>
  import(
    /* webpackChunkName: "Insights", webpackPrefetch: true  */
    '../../Views/Templates/index'
  )
)

const CreateTemplate = lazy(() => 
    import(
      /* webpackChunkName: "Insights", webpackPrefetch: true  */
      '../../Views/Templates/CreateTemplate'
    )
)

const EditTemplate = lazy(() => 
    import(
      /* webpackChunkName: "Insights", webpackPrefetch: true  */
      '../../Views/Templates/EditTemplate'
    ))

const MessagingView = lazy(() =>
  import(
    '../../Views/Messaging/MessagingView/MessagingView'
  )
)

const YourFilters = lazy(() =>
  import(
    '../../Views/Filters/YourFiltersPage'
  )
)

const CreateFilter = lazy(() => 
    import(
      /* webpackChunkName: "Insights", webpackPrefetch: true  */
      '../../Views/Filters/CreateFilter/CreateFilter'
    )
)

const EditFilter = lazy(() =>
    import(
      /* webpackChunkName: "Insights", webpackPrefetch: true  */
      '../../Views/Filters/EditFilter/EditFilter'
    )
)

// Quick and dirty fix as runtime env variables need to be passed to client but infrastructure does not support this
const env = (() => {
  const { hostname } = location

  if (
    [
      'staging-e-tenders.headbox.com',
      'uat-e-tenders.headbox.com',
      'dev.headbox.com',
    ].includes(hostname)
  ) {
    return {
      GUEST_EVENTS_BASE_URI: 'https://experimental-my-events.headbox.com',
    }
  }

  return {
    GUEST_EVENTS_BASE_URI: 'https://my-events.headbox.com',
  }
})()

const Router = () => {
  useProductFruits()

  const routes = [
    {
      component: () => <Redirect to={LOGIN} />,
      path: HOME,
    },
    {
      component: Login,
      path: LOGIN,
    },
    {
      component: LeadFeed,
      path: LEADS,
    },
    {
      component: () => <Redirect to={LEADS} />,
      path: ENQUIRIES,
    },
    {
      component: MessagingView,
      path: MESSAGING,
    },
    {
      component: InterestedGuests,
      path: GUEST_INTEREST,
    },
    {
      component: SentMessages,
      path: SENT_MESSAGES
    },
    {
      component: () => <Redirect to={YOUR_FILTERS} />,
      path: PREFERENCES,
    },
    {
      component: Insights,
      path: INSIGHTS,
    },
    {
      component: Templates,
      path: TEMPLATES,
    },
    {
      component: CreateTemplate,
      path: CREATE_TEMPLATE
    },
    { 
      component: EditTemplate,
      path: EDIT_TEMPLATE
    },
    {
      component: YourFilters,
      path: YOUR_FILTERS
    },
    {
      component: CreateFilter,
      path: CREATE_FILTER
    },
    {
      component: EditFilter,
      path: EDIT_FILTER
    },

    // Remove once old events have passed (4 weeks???)
    // here
    {
      component: () => {
        window.location.assign(
          `${GUEST_EVENTS_SERVICE}/responses${window.location.search}`
        )
      },
      path: RESPONSES,
    },
    // to here
    { component: PageNotFound }, // keep last in stack
  ]
  return (
    <BrowserRouter>
      <Suspense fallback={<Spin />}>
        <Switch>
          {routes.map(({ path, component }, index) => {
            return (
              <Route
                key={`${path}_${index}`}
                exact={!!path}
                path={path}
                component={component}
              />
            )
          })}
        </Switch>
      </Suspense>
    </BrowserRouter>
  )
}

export default Router
